<template>
  <div class="sw-pt-11 mx-auto contact-page">
    <transition name="fade">
      <v-window v-model="window">
        <v-window-item :value="0">
          <v-card flat max-width="894">
            <ValidationObserver ref="contactForm">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="contact-heading">
                    <div class="sw-page-title">
                      {{ $vuetify.lang.t("$vuetify.contactUs") }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="sw-mb-7 contact-description">
                  <div class="sw-page-subtitle">
                    {{ $vuetify.lang.t("$vuetify.contactUsDescription") }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-row no-gutters class="sw-pr-2">
                    <v-col cols="12">
                      <div class="sw-pl-2 sw-small font-weight-light">
                        {{ $vuetify.lang.t("$vuetify.nameSurname") }}
                      </div>
                      <ValidationProvider
                        vid="name"
                        :name="$vuetify.lang.t('$vuetify.nameSurname')"
                        mode="passive"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="message.name"
                          :error-messages="errors[0]"
                          :placeholder="
                            $vuetify.lang.t('$vuetify.nameSurname') + '*'
                          "
                          flat
                          background-color="#F2F2F2"
                          class="rounded-lg form-field"
                          solo
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <div class="sw-pl-2 sw-small font-weight-light">
                        {{ $vuetify.lang.t("$vuetify.email") }}
                      </div>
                      <ValidationProvider
                        vid="email"
                        :name="$vuetify.lang.t('$vuetify.email')"
                        mode="passive"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="message.email"
                          :error-messages="errors[0]"
                          :placeholder="$vuetify.lang.t('$vuetify.email') + '*'"
                          flat
                          background-color="#F2F2F2"
                          class="rounded-lg form-field"
                          solo
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <div class="sw-pl-2 sw-small font-weight-light">
                        {{ $vuetify.lang.t("$vuetify.phone") }}
                      </div>
                      <ValidationProvider
                        vid="phone"
                        :name="$vuetify.lang.t('$vuetify.phone')"
                        mode="passive"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="message.phone"
                          :error-messages="errors[0]"
                          :placeholder="$vuetify.lang.t('$vuetify.phone') + '*'"
                          flat
                          background-color="#F2F2F2"
                          class="rounded-lg form-field"
                          solo
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row no-gutters class="sw-pl-2">
                    <v-col cols="12">
                      <div class="sw-pl-2 sw-small font-weight-light">
                        {{ $vuetify.lang.t("$vuetify.message") }}
                      </div>
                      <ValidationProvider
                        vid="message"
                        :name="$vuetify.lang.t('$vuetify.message')"
                        mode="passive"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-textarea
                          v-model="message.message"
                          :error-messages="errors[0]"
                          :placeholder="
                            $vuetify.lang.t('$vuetify.message') + '*'
                          "
                          no-resize
                          flat
                          background-color="#F2F2F2"
                          class="rounded-lg form-field form-textarea"
                          solo
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                    <template v-if="convertToPlain(signupPrivacyDisclaimer)">
                      <v-col v-if="signupRequiredPrivacyDisclaimer" cols="12">
                        <ValidationProvider
                          vid="terms_and_conditions"
                          :name="$vuetify.lang.t('$vuetify.termsAndConditions')"
                          rules="required"
                          mode="passive"
                          v-slot="{ errors }"
                        >
                          <checkbox
                            v-model="termsAccepted"
                            :true-value="true"
                            :false-value="null"
                            :error-messages="errors[0]"
                          >
                            <template v-slot:label>
                              <div v-html="signupPrivacyDisclaimer"></div>
                            </template>
                          </checkbox>
                        </ValidationProvider>
                      </v-col>
                      <v-col v-if="!signupRequiredPrivacyDisclaimer" cols="12">
                        <div v-html="signupPrivacyDisclaimer"></div>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div class="sw-pt-2 text-center">
                    <v-btn
                      rounded
                      large
                      depressed
                      :style="{ backgroundColor: gmSecondaryColor }"
                      class="white--text text-none action-button"
                      :loading="isLoading"
                      @click="sendMessage"
                    >
                      <span class="sw-px-2">{{
                        $vuetify.lang.t("$vuetify.send")
                      }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-card>
        </v-window-item>
        <v-window-item :value="1">
          <v-row no-gutters>
            <v-col cols="12" class="sw-mb-9">
              <div v-html="signupContactFormThankYouText"></div>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                large
                depressed
                :style="{ backgroundColor: gmSecondaryColor }"
                class="white--text text-none action-button"
                :loading="isLoading"
                @click="$router.push({ name: 'FrontPage' })"
              >
                <span class="sw-px-2">
                  {{ $vuetify.lang.t("$vuetify.goToHomePage") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </transition>
  </div>
</template>

<script>
import Checkbox from "@/components/CustomFields/Checkbox.vue";

export default {
  data: () => ({
    isLoading: false,
    plugin: {},
    message: {},
    termsAccepted: null,
    window: 0,
  }),
  computed: {
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    signupRequiredPrivacyDisclaimer() {
      return this.plugin?.attributes?.signup_required_privacy_disclaimer;
    },
    signupPrivacyDisclaimer() {
      return this.plugin?.attributes?.signup_privacy_disclaimer;
    },
    signupContactFormThankYouText() {
      return this.$store?.getters?.groupAttributes
        ?.lp_contact_form_thank_you_text;
    },
  },
  components: {
    Checkbox,
  },
  watch: {
    appLanguage: {
      async handler() {
        await this.fetch();
      },
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.isLoading = true;

        await this.getSignupPlugin();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async getSignupPlugin() {
      const params = {
        prefix: "signup",
        with_attributes: 1,
        per_page: 100,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(`/groups/${this.groupId}/plugins`, {
        params,
      });

      if (!response?.data?.data?.[0]) return;

      this.plugin = response.data.data[0];
    },
    async sendMessage() {
      const isValid = await this.$refs.contactForm.validate();

      if (!isValid) return;

      try {
        this.isLoading = true;

        await this.$http.post(
          `/groups/${this.groupId}/contact_form`,
          this.message,
        );

        this.isLoading = false;

        this.window = 1;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.contact-page {
  width: 100%;
  max-width: 1380px;

  .contact-heading {
    max-width: 600px;
  }

  .contact-description {
    max-width: 600px;
  }

  .form-field.v-text-field.v-text-field--solo > .v-input__control {
    min-height: 48px;
    border-radius: 20px;
  }

  .form-field .v-input__slot {
    padding-left: 15px !important;
  }

  .form-field.form-textarea.v-text-field.v-text-field--solo
    > .v-input__control {
    min-height: 184px;
  }

  .action-button {
    margin-top: 3px;
    border-radius: 20px;
    height: 52px !important;
    font-size: 18px;
    letter-spacing: unset;
    min-width: 130px !important;
  }
}

@media (max-width: 1500px) {
  .contact-page {
    max-width: 890px;
  }
}

@media (max-width: 1100px) {
  .contact-page {
    max-width: 760px;
  }
}

@media (max-width: 900px) {
  .contact-page {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 130px !important;

    .contact-description {
      font-size: 15px;
      margin-bottom: 40px !important;
    }
  }
}

@media (max-width: 600px) {
  .contact-page {
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .sw-pr-2 {
      padding-right: 0 !important;
    }

    .sw-pl-2 {
      padding-left: 0 !important;
    }

    .form-field.v-text-field.v-text-field--solo > .v-input__control {
      min-height: 48px;
    }

    .form-field {
      min-height: unset;
    }

    .form-field.form-textarea.v-text-field.v-text-field--solo
      > .v-input__control {
      min-height: unset;
    }
  }
}
</style>
