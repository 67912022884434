<template>
  <div class="checkbox">
    <v-row no-gutters>
      <v-col cols="12">
        <label class="d-flex align-top">
          <div class="box">
            <font-awesome-icon
              v-if="model"
              :icon="['fas', 'check']"
              class="icon"
            />
          </div>
          <slot name="label">
            <div>{{ label }}</div>
          </slot>
          <input v-model="model" type="checkbox" />
        </label>
      </v-col>
      <v-col cols="12" class="error-message">
        <transition name="fade" mode="out-in">
          <div v-if="errorMessages">{{ errorMessages }}</div>
        </transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: String,
    },
  },
  computed: {
    model: {
      get() {
        return Boolean(this.value);
      },
      set(val) {
        this.$emit("input", val ? this.trueValue : this.falseValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  label {
    margin-bottom: 8px;
    cursor: pointer;

    .box {
      margin: 4px 10px 0 0;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      border-radius: 7px;
      text-align: center;
      background: #f2f2f2;

      .icon {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  .error-message {
    min-height: 14px;
    padding: 0 12px;
    font-size: 12px;
    line-height: 12px;
    color: #ff5252 !important;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
}
</style>
